@import "minima";

.listen-music {
  float:left;
  margin: 0 20px 0 0;

  @include media-query($on-palm) {
    display: flex;
    flex-direction: column;
    float: none;
    margin: 0%;
  }

  .release-art {
    box-shadow: 0 9px 18px 0 rgba($grey-color-dark, 0.2);
  }

  ul {
    padding-top: 5px;
    margin-left: 0px;
    list-style: none;
    display: flex;
    justify-content: space-around;

    li:hover {
      transform: translateY(-2px);
    }
  
    li:active {
      transform: translateY(0);
    }
  
    img, svg {
      width: 32px;
      height: 32px;
    }

    .yt-minima {
      transform: scale(2) translate(10px, 8px);
    }

    .tidal {
      transform: scale(0.73);
      width: auto;
      padding: 0px;
    }
  }

  button {
    // background-color: #fff9c4;
    background-color: #bdbdbd;
    border: none;
    border-radius: 9px;
    // color: #bdbdbd;
    padding: 7px;
  }
}

.releases-content {
  display: flex;
}

.releases-content img {
  max-width: 30%;
  height: fit-content;
  margin-right: 11px;
}

@include media-query($on-palm) {
  .releases-content p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: block-axis;
  }
}

.site-header {
  font: $base-font-weight #{$base-font-size}/#{$base-line-height} $header-font-family;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $heading-font-family;
}

p {
  text-align: justify;
}

.site-footer {
  font-family: $heading-font-family;
}

blockquote {
  font-size: inherit;
}

.name {
  line {
    stroke: $grey-color-dark;
    stroke-width: 0.5;
  }
  .sound {
    opacity: 0;
  }
  text {
    fill: $grey-color-dark;
    font-family: "Alegreya SC";
    font-weight: 300;
    font-size: 9px;
  }
  .cardoso {
    transform-origin: center;
  }
  .site {
    opacity: 0;
    transform-origin: center;
  }
}

.flip-box {
  background-color: transparent;
  width: 600px;
  height: 480px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-box-front, .flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.flip-box-back {
  transform: rotateY(180deg);
}

.news {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.social-collapsed {
  overflow: hidden;

  li {
    float: left;
  }

  li + li {
    padding-top: 0px;
  }

  img {
    width: 22px;
    height: 22px;  
  }

  svg {
    vertical-align: middle;
  }

  span {
    display: none;
  }
}

.ouvir {
  background-color: #252301;

  div {
    display: flex;
  
    li {
      margin-left: 10px;
    }
  
    li:hover {
      transform: translateY(-2px);
    }
  
    li:active {
      transform: translateY(0);
    }
  
    img, svg {
      width: 32px;
      height: 32px;
      vertical-align: middle;
    }
  
    .yt-minima {
      transform: scale(2) translate(10px, 8px);
    }
  
    .tidal {
      transform: scale(0.73);
      width: auto;
      padding: 0px;
    }
  }
}
