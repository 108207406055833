@charset "utf-8";

// Define defaults for each variable.

// mudanças nas fontes - start
@font-face {
	font-family: "Alegreya";
	src: url("alegreya/AlegreyaSans-MediumItalic.ttf");
}

@font-face {
	font-family: "Alegreya SC";
	src: url("alegreya/AlegreyaSansSC-ExtraBoldItalic.ttf");
}

$base-font-family: "Rubik", sans-serif !default;
$header-font-family: "Alegreya SC", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$heading-font-family: "Alegreya", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
// mudanças nas fontes - end
$base-font-size:   21px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;

// mudanças nas cores - start
$text-color:       #bdbdbd !default;
$background-color: #212121 !default;
$brand-color:      #2196f3 !default;

$grey-color:       #fff9c4 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;
// mudanças nas cores - end

$table-text-align: left !default;

// Width of the content area
$content-width:    800px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import
  "minima/base",
  "minima/layout",
  "minima/syntax-highlighting"
;
